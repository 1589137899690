import { InstagramOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  message,
  Row,
  Skeleton,
  Space,
  Spin,
} from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Api } from "../api";
import { Helpers } from "../helpers";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { useSubmitForm } from "../helpers/forms";
import { FieldErrorMessage } from "./FieldErrorMessage";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SettingsPage = () => {
  const [instagramToken, setInstagramToken] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let query = useQuery();

  const id = "647509c8d1fdf6874cc8af91";
  const { handleSubmit, errors, control, reset, watch } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "heroNavigator", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const onSuccess = () => {
    message.success("Saved");
  };
  const imageFields = [
    {
      name: "heroSection2.image",
      singleValue: true,
    },
    {
      name: "heroSection3.image",
      singleValue: true,
    },
  ];
  const LoadFormResult = useLoadFormData({
    apiCall: Api.pages.single,
    id,
    setValue: reset,
    defaultValues: {
      heroNavigator: [
        {
          text: "test",
        },
        {},
        {},
        {},
      ],
    },
    imageFields,
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.pages.create,
    updateApiCall: Api.pages.update,
    onSuccess,
    imageFields,
    updateId: id,
  });
  // const [loading, setLoading] = useState(false);

  const authHref = new URLSearchParams({
    client_id: "640276354461198",
    redirect_uri: Helpers.isProduction
      ? "https://cms.thenkmethod.com/settings"
      : "https://localhost:3000/settings",
    scope: "user_profile,user_media",
    response_type: "code",
  });
  const fetchCurrentToken = async () => {
    setLoading(true);
    const res = await Api.pages.singleWithSlug(encodeURIComponent("/"));
    setInstagramToken(res.data.data.instagram);
    setLoading(false);
  };
  
  const checkCode = async () => {
    const instagramCode = query.get("code");
    if (instagramCode && instagramCode !== "") {
      setLoading(true);
      try {
        const response = await Api.users.exchangeCodeWithToken(instagramCode);
        console.log("setting code to null");
        history.push({
          pathname: window.location.pathname,
          search: "",
        });
        await fetchCurrentToken();
      } catch (err) {
        message.error("couldnt fetch token");
      }
      setLoading(false);
    }
  };

  const onRevokeToken = async () => {
    setLoading(true);
    await Api.users.revokeToken();
    await fetchCurrentToken();
    setLoading(false);
  };

  useEffect(() => {
    const instagramCode = query.get("code");
    if (instagramCode && instagramCode !== "") {
      checkCode();
    } else {
      fetchCurrentToken();
    }
  }, []);

  //loading
  if (LoadFormResult[1]) {
    return <Skeleton />;
  }
  return (
    <div style={{ backgroundColor: "white", minHeight: "60vh", padding: 10 }}>
      <h2>
        Link to Instagram Account to show your most recent posts as part of the website design
      </h2>

      {loading ? (
        <Spin />
      ) : instagramToken && instagramToken.accessToken ? (
        <>
          <p>
            Instagram token generated on {Helpers.formatDateFull(instagramToken?.dateGenerated)}
          </p>
          {/*@ts-ignore */}
          <Button onClick={onRevokeToken} type="danger">
            Revoke Token
          </Button>
        </>
      ) : (
        <Button
          href={`https://www.instagram.com/oauth/authorize?${authHref.toString()}`}
          type="default"
          icon={<InstagramOutlined />}
        >
          Link To Instagram
        </Button>
      )}

      <form onSubmit={handleSubmit(submit)}>
        <Space style={{ width: "100%", marginTop: 25 }} direction="vertical">
          <Row gutter={12}>
            <Col span={6}>
              <label htmlFor={"instagram.numberOfPosts"}>Number Of Posts To Show</label>
              <Controller
                control={control}
                as={<InputNumber style={{ width: "100%" }} id={"instagram.numberOfPosts"} />}
                rules={{ required: false }}
                name="instagram.numberOfPosts"
              />
              <FieldErrorMessage errors={errors} name="instagram.numberOfPosts" />
            </Col>
          </Row>
          <Button loading={loadingSubmit} htmlType="submit" role={"submit"}>
            Submit
          </Button>
        </Space>
      </form>
    </div>
  );
};

export default SettingsPage;
