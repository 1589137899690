import React from "react";
import {
  useFieldArray,
  Controller,
  Control,
  FieldErrors,
} from "react-hook-form";
import { Col, Row, Input, Button, Space, Card } from "antd";
import Icon, { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { FileUploader } from "../FileUploader";
import { FieldErrorMessage } from "../FieldErrorMessage";

type TestimonialArrayField = {
  control: Control;
  fieldName: string;
  errors: FieldErrors<any>;
};
const TestimonialArrayField = ({
  control,
  fieldName,
  errors,
}: TestimonialArrayField) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: fieldName,
    }
  );

  return (
    <Card bodyStyle={{ padding: 15 }} title="Testimonials">
      <Space style={{ width: "100%" }} direction="vertical">
        {fields.map((item, index) => {
          return (
            <Row key={index} style={{ width: "100%" }} gutter={12}>
              <Col span={24}>
                <Input.Group size="default">
                  <Row gutter={2}>
                    <Col span={24}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={
                          <Input
                            placeholder="Name"
                            id={`${fieldName}[${index}].name`}
                          />
                        }
                        name={`${fieldName}[${index}].name`}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={
                          <Input
                            style={{ direction: "rtl" }}
                            placeholder="Arabic Name"
                            id={`${fieldName}[${index}].arabicName`}
                          />
                        }
                        name={`${fieldName}[${index}].arabicName`}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={<FileUploader singleValue />}
                        name={`${fieldName}[${index}].image`}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={
                          <Input
                            placeholder="text"
                            id={`${fieldName}[${index}].text`}
                          />
                        }
                        name={`${fieldName}[${index}].text`}
                      />
                    </Col>

                    <Col span={2}>
                      <Button
                        block
                        style={{ height: "100%" }}
                        onClick={() => {
                          remove(index);
                        }}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Col>
                    <Col span={24}>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        as={
                          <Input
                            style={{ direction: "rtl" }}
                            placeholder="Arabic Text"
                            id={`${fieldName}[${index}].arabicText`}
                          />
                        }
                        name={`${fieldName}[${index}].arabicText`}
                      />
                    </Col>

                    <Col span={2}>
                      <Button
                        block
                        style={{ height: "100%" }}
                        onClick={() => {
                          remove(index);
                        }}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Col>
                  </Row>
                </Input.Group>

                <FieldErrorMessage
                  errors={errors}
                  name={`${fieldName}[${index}].name`}
                />
                <FieldErrorMessage
                  errors={errors}
                  name={`${fieldName}[${index}].arabicName`}
                />
                <FieldErrorMessage
                  errors={errors}
                  name={`${fieldName}[${index}].image`}
                />
              </Col>
            </Row>
          );
        })}
        <Button
          block
          onClick={() => {
            append({});
          }}
          icon={<PlusOutlined />}
        ></Button>
        <FieldErrorMessage errors={errors} name={fieldName} />
      </Space>
    </Card>
  );
};

export default TestimonialArrayField;
