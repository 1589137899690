import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Result,
  Row,
  Select,
  Space,
} from "antd";
import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../../api";
import { useSubmitForm } from "../../helpers/forms";
import { ImagesHelpers } from "../../helpers/images";
import { useLoadFormData } from "../../hooks/useLoadFormData";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FileUploader } from "../FileUploader";
import { Helmet } from "react-helmet";
import CategorySelector from "../categories/CategorySelector";
import TextEditorInput from "../TextEditorInput";
import FileUploadV2 from "../fileUploadV2/FileUploadV2";
import { TagsInput } from "../tags/TagsInput";
import moment from "moment";
import CourseContentField from "../CourseContentField";
import TestimonialArrayField from "../pages/TestimonialArrayField";
import OnlineCourseSectionsField from "../OnlineCourseSectionsField";
import VideoPickerModal from "../VideoPicker";

const CourseForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm();
  let history = useHistory();
  const onSuccess = () => {
    message.success("Saved");
    history.push("/courses");
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.courses.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const courseType = useWatch({ control, name: "type", defaultValue: null });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.courses.create,
    updateApiCall: Api.courses.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/courses">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Helmet>
        <title>Create Course | {process.env.REACT_APP_PROJECT_NAME}</title>
      </Helmet>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={6}>
            <label htmlFor={"title"}>Title</label>
            <Controller
              control={control}
              as={<Input id={"title"} />}
              rules={{ required: true }}
              name="title"
            />
          </Col>
          <Col span={6}>
            <label htmlFor={"subTitle"}>Subtitle</label>
            <Controller
              control={control}
              as={<Input id={"subTitle"} />}
              rules={{ required: false }}
              name="subTitle"
            />
            <FieldErrorMessage errors={errors} name="subTitle" />
          </Col>
          <Col span={6}>
            <label htmlFor={"type"}>Type</label>
            <Controller
              control={control}
              as={
                <Select
                  style={{ width: "100%" }}
                  options={[
                    { label: "Virtual", value: "virtual" },
                    { label: "Online", value: "online" },
                    { label: "In-person", value: "offline" },
                    { label: "Private", value: "private" },
                  ]}
                />
              }
              rules={{ required: true }}
              name="type"
            />
            <FieldErrorMessage errors={errors} name="type" />
          </Col>
        </Row>
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={6}>
            <label htmlFor={"arabicTitle"}>Arabic Title</label>
            <Controller
              control={control}
              as={<Input style={{ direction: "rtl" }} id={"arabicTitle"} />}
              rules={{ required: true }}
              name="arabicTitle"
            />
          </Col>
          <Col span={6}>
            <label htmlFor={"arabicSubTitle"}>Arabic Subtitle</label>
            <Controller
              control={control}
              as={<Input style={{ direction: "rtl" }} id={"arabicSubTitle"} />}
              rules={{ required: false }}
              name="arabicSubTitle"
            />
            <FieldErrorMessage errors={errors} name="arabicSubTitle" />
          </Col>
        </Row>
        {courseType === "virtual" && (
          <Col span={6}>
            <label htmlFor={"meetingLink"}>Meeting Link</label>
            <Controller
              control={control}
              as={<Input id={"meetingLink"} />}
              rules={{ required: false }}
              name="meetingLink"
            />
            <FieldErrorMessage errors={errors} name="meetingLink" />
          </Col>
        )}

        {(courseType === "online" || courseType === "offline") && (
          <OnlineCourseSectionsField
            control={control}
            errors={errors}
            fieldName="onlineCourseSections"
          />
        )}

        {(courseType === "online" || courseType === "offline") && (
          <Col span={12}>
            <label htmlFor={"files"}>Files</label>
            <Controller control={control} as={<FileUploadV2 />} name="files" />
            <FieldErrorMessage errors={errors} name="files" />
          </Col>
        )}

        <Col span={24}>
          <label htmlFor={"teaserVideo"}>Teaser Video</label>
          <Controller
            control={control}
            as={<VideoPickerModal />}
            rules={{ required: false }}
            name={"teaserVideo"}
          />
          <FieldErrorMessage errors={errors} name={"teaserVideo"} />
        </Col>

        <div style={{ width: "50%" }}>
          {/* <span style={{ color: "#6F7287" }} className="text-sm">Extra Info Tabs</span> */}
          <CourseContentField
            control={control}
            errors={errors}
            fieldName="courseContent"
          />
        </div>
        {courseType === "virtual" && (
          <Row gutter={8}>
            <Col span={6}>
              <label htmlFor={"date.from"}>Date From </label>
              <div>
                <Controller
                  control={control}
                  render={({ onChange, value }) => (
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(v) => {
                        onChange(v?.toDate());
                      }}
                      value={value ? moment(value) : null}
                      style={{ width: "100%" }}
                    />
                  )}
                  rules={{ required: true }}
                  name="date.from"
                />
              </div>
              <FieldErrorMessage errors={errors} name="date.from" />
            </Col>
            <Col span={6}>
              <label htmlFor={"date.to"}>Date To </label>
              <div>
                <Controller
                  render={({ onChange, value }) => (
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(v) => {
                        onChange(v?.toDate());
                      }}
                      value={value ? moment(value) : null}
                      style={{ width: "100%" }}
                    />
                  )}
                  control={control}
                  rules={{ required: true }}
                  name="date.to"
                />
              </div>
              <FieldErrorMessage errors={errors} name="date.to" />
            </Col>
          </Row>
        )}
        <Row>
          <Col span={6}>
            <label htmlFor={"price"}>Price</label>
            <div>
              <Controller
                control={control}
                as={
                  <InputNumber style={{ width: "100%" }} min={0} id={"price"} />
                }
                rules={{ required: false }}
                name="price"
              />
            </div>
            <FieldErrorMessage errors={errors} name="price" />
          </Col>
          <Col span={6}>
            <label htmlFor={"priceBefore"}>
              Price before (will be shown with a strikethrough)
            </label>
            <div>
              <Controller
                control={control}
                as={
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    id={"priceBefore"}
                  />
                }
                rules={{ required: false }}
                name="priceBefore"
              />
            </div>
            <FieldErrorMessage errors={errors} name="priceBefore" />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <label htmlFor={"categories"}>Categories</label>
            <Controller
              control={control}
              as={<CategorySelector multi />}
              rules={{ required: true }}
              name="categories"
            />
            <FieldErrorMessage errors={errors} name="categories" />
          </Col>
          <Col span={6}>
            <label htmlFor={"tags"}>Tags</label>
            <Controller
              control={control}
              as={<TagsInput />}
              rules={{ required: true }}
              name="tags"
            />
            <FieldErrorMessage errors={errors} name="tags" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor={"thumbnailImage"}>Thumbnail Image</label>
            <Controller
              control={control}
              as={<FileUploadV2 singleValue />}
              name="thumbnailImage"
            />
            <FieldErrorMessage errors={errors} name="thumbnailImage" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor={"smallDescription"}>Small Description</label>
            <Controller
              control={control}
              as={<Input.TextArea rows={4} id={"smallDescription"} />}
              rules={{ required: false }}
              name="smallDescription"
            />
            <FieldErrorMessage errors={errors} name="smallDescription" />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor={"smallDescriptionArabic"}>
              Small Description in Arabic
            </label>
            <Controller
              control={control}
              as={
                <Input.TextArea
                  style={{ direction: "rtl" }}
                  rows={4}
                  id={"smallDescriptionArabic"}
                />
              }
              rules={{ required: false }}
              name="smallDescriptionArabic"
            />
            <FieldErrorMessage errors={errors} name="smallDescriptionArabic" />
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={24}>
            <label htmlFor={"description"}>Description</label>
            <Controller
              control={control}
              as={<TextEditorInput />}
              name="description"
            />
            <FieldErrorMessage errors={errors} name="description" />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <label htmlFor={"arabicDescription"}>Arabic Description</label>
            <Controller
              control={control}
              as={<TextEditorInput style={{ direction: "rtl" }} />}
              name="arabicDescription"
            />
            <FieldErrorMessage errors={errors} name="arabicDescription" />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <TestimonialArrayField
              fieldName="testimonials"
              control={control}
              errors={errors}
            />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button
        loading={loading || loadingSubmit}
        htmlType="submit"
        role={"submit"}
      >
        Submit
      </Button>
    </form>
  );
};

export default CourseForm;
