import React, { useEffect, useState } from "react";
import { Modal, Upload, Button, List, Checkbox } from "antd";
import { DeleteOutlined, UploadOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Api } from "../api";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";

interface VideoModalProps {
  onChange?: (videoId?: string) => void;
  value?: string;
}

interface VideoItem {
  fileId: string;
  outputPaths: string[];
  isCompleted: boolean;
  filePathS3: string;
  _id: string;
}

const VideoPickerModal: React.FC<VideoModalProps> = ({ onChange, value }) => {
  const [selectedVideo, setSelectedVideo] = useState<string | undefined>(value);
  const [videoList, setVideoList] = useState<VideoItem[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    setIsVisible(false);
  };
  const onOpen = () => {
    setIsVisible(true);
  };

  const handleVideoSelection = (videoId: string) => {
    // setSelectedVideo((prevSelected) => {
    //   if (prevSelected.includes(videoId)) {
    //     return prevSelected.filter((id) => id !== videoId);
    //   } else {
    //     return [...prevSelected, videoId];
    //   }
    // });
    setSelectedVideo(videoId);
  };
  const fetchFiles = () => {
    setIsLoading(true);
    Api.videos
      .list()
      .then((res) => {
        setVideoList(res.data.reverse());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const onClickOk = () => {
    onChange && onChange(selectedVideo);
    setIsVisible(false);
  };

  const getFileName = (fileId: string) => {
    const file = videoList.find((i) => i._id === fileId);

    if (file) return file.filePathS3?.substring(file.filePathS3.lastIndexOf("/") + 1);
    else return fileId;
  };

  return (
    <>
      <Modal
        destroyOnClose
        title="Video Picker"
        visible={isVisible}
        onCancel={onCancel}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" onClick={onClickOk}>
            OK
          </Button>,
        ]}
      >
        <List
          style={{ height: 300, overflowY: "scroll" }}
          dataSource={videoList}
          loading={isLoading}
          renderItem={(video) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={() => handleVideoSelection(video._id)}
              key={video._id}
              //   actions={[
              //     <Checkbox
              //       checked={selectedVideos.includes(video._id)}
              //       onChange={() => handleVideoSelection(video._id)}
              //     />,
              //     // <Button onClick={() => handleVideoRemove(video._id)}>Remove</Button>,
              //   ]}
            >
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <Checkbox
                  //   checked={selectedVideo.includes(video._id)}
                  checked={selectedVideo === video._id}
                  onChange={() => handleVideoSelection(video._id)}
                />
                <VideoCameraOutlined />
                <span>{video.filePathS3?.substring(video.filePathS3.lastIndexOf("/") + 1)}</span>
                <a rel="noreferrer" target="_blank" href={video.outputPaths[0]}>
                  Preview
                </a>
              </div>
            </List.Item>
          )}
        />
        <FileUploadV2 limit={1} singleValue isVideoFile is onChange={fetchFiles} />
      </Modal>
      <div>
        {value && (
          <div>
            {getFileName(value)}
            <Button
              style={{ marginLeft: 5 }}
              icon={
                <DeleteOutlined
                  onClick={() => {
                    if (onChange) onChange(undefined);
                  }}
                />
              }
            ></Button>
          </div>
        )}
        <Button onClick={onOpen}>{value ? "Change Video" : "Chose Video"}</Button>
      </div>
    </>
  );
};

export default VideoPickerModal;
