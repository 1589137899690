import React from "react";
import { Link } from "react-router-dom";
import { Table } from "../Table";
import { Api } from "../../api";
import { Space } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "../BasicTableFilter";
import { OrderStatus, PaymentMethods } from "../../types/order";
import { Helpers } from "../../helpers";
import OrdersTableFilter from "../orders/OrdersFilter";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    width: "10%",
    key: "_id",
    // render: (text: string) => {
    //   return <Link to={`/orders/update/${text}`}>{text}</Link>;
    // },
  },
  {
    title: "Url",
    dataIndex: "_id",
    width: "10%",
    render: (text: string, item: any) => {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_WEBSITE_URL}/orders/${text}`}
        >
          View Order
        </a>
      );
    },
  },
  {
    title: "User",
    dataIndex: "user",
    render: (user: any, item: any) => {
      if (!user)
        return null;
      return <Link to={`/website-user/update/${user._id}`}>
        {user.firstName} {user.lastName}
      </Link>;
    },
  },
  // {
  //     title: 'Customer',
  //     dataIndex: 'address',
  //     width: '20%',
  //     render: (address: any, item: any) => {
  //         return (<p>{address.firstName} {address.lastName}</p>)
  //     }
  // },
  {
    title: "Total",
    dataIndex: "payment",
    render: (payment: any, item: any) => {
      return <p>${payment.total}</p>;
    },
  },
  {
    title: "Country",
    dataIndex: "country",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status: any, item: any) => {
      return <p>{OrderStatus.format(status)}</p>;
    },
  },
  {
    title: "Ordered On",
    dataIndex: "createdAt",
    sorter: true,
    render: (createdAt: any, item: any) => {
      return <p>{Helpers.formatDateFull(createdAt)}</p>;
    },
  },
];

const OrderTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        tableId={"ordersTable"}
        deleteApiCall={Api.orders.delete}
        allowDelete={false}
        defaultSort={{ createdAt: -1 }}
        restoreApiCall={Api.orders.restore}
        // actions={(record: any) => (
        //     <Link to={`/orders/update/${record._id}`}>View</Link>
        // )}
        Filter={OrdersTableFilter}
        apiCall={Api.orders.table}
        columns={columns}
      />
    </Space>
  );
};

export default OrderTable;
