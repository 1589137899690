import {
  BoxPlotOutlined,
  DashboardOutlined,
  FormOutlined,
  HeatMapOutlined,
  OrderedListOutlined,
  PlusOutlined,
  SettingOutlined,
  TagOutlined,
  TagsOutlined,
  TeamOutlined,
  UploadOutlined,
} from "@ant-design/icons/lib";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Api } from "./api";
import CategoriesForm from "./components/categories/CategoriesCreate";
import CategoriesTable from "./components/categories/CategoriesList";
import ContactFormForm from "./components/contactForm/ContactFormForm";
import ContactFormTable from "./components/contactForm/ContactFormTable";
import CourseSinglePage from "./components/courses/CourseSinglePage";
import CourseTable from "./components/courses/CourseTable";
import OrderSinglePage from "./components/orders/OrderSinglePage";
import OrderTable from "./components/orders/OrderTable";
import PageForm from "./components/PageForm";
import PageTable from "./components/PageTable";
import PromoCodeForm from "./components/promocode/PromoCodeForm";
import PromoCodeTable from "./components/promocode/PromoCodeTable";
import TagForm from "./components/tags/TagForm";
import TagTable from "./components/tags/TagTable";
import WebsiteUserSinglePage from "./components/websiteUsers/WebsiteUsersSinglePage";
import WebsiteUsersTable from "./components/websiteUsers/WebsiteUsersTable";
import SettingsPage from "./components/SettingsPage";

const Dashboard = React.lazy(() => import("./components/Dashboard"));
const UsersForm = React.lazy(() => import("./components/users/UsersCreate"));
const UsersList = React.lazy(() => import("./components/users/UsersList"));

export const Routes: any = [
  {
    title: "Dashboard",
    component: Dashboard,
    icon: DashboardOutlined,
    hideFromMenu: false,
    breadcrumb: "I love sandwiches",
    path: "/",
    isHomePage: true,
  },

  {
    title: "Users",
    icon: TeamOutlined,
    dataRoute: Api.users.statistics,
    path: "/users",
    buttons: [
      <Link key="create" to="/users/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add User
        </Button>
      </Link>,
    ],
    component: UsersList,
    routes: [
      {
        title: "Create",
        icon: PlusOutlined,
        path: "/users/create",
        component: UsersForm,
      },
      {
        title: "Update",
        hideFromMenu: true,
        path: "/users/update/:id",
        component: UsersForm,
      },
    ],
  },

  {
    title: "Website Users",
    icon: TeamOutlined,
    dataRoute: Api.users.statistics,
    path: "/website-user",
    // buttons: [
    //   <Link key="create" to="/users/create">
    //     <Button type="primary" icon={<PlusOutlined />}>
    //       Add User
    //     </Button>
    //   </Link>,
    // ],
    component: WebsiteUsersTable,
    routes: [
      {
        title: "Update",
        hideFromMenu: true,
        path: "/website-user/update/:id",
        component: WebsiteUserSinglePage,
      },
    ],
  },

  {
    title: "Courses",
    icon: HeatMapOutlined,
    dataRoute: Api.courses.statistics,
    path: "/courses",
    buttons: [
      <Link key="create" to="/courses/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Course
        </Button>
      </Link>,
    ],
    component: CourseTable,
    routes: [
      {
        title: "Create",
        icon: PlusOutlined,
        path: "/courses/create",
        component: CourseSinglePage,
      },
      {
        title: "Update",
        hideFromMenu: true,
        path: "/courses/update/:id",
        component: CourseSinglePage,
      },
    ],
  },
  {
    title: "Promo Codes",
    icon: TagOutlined,
    path: "/promoCodes",
    dataRoute: Api.promoCodes.statistics,
    component: PromoCodeTable,
    buttons: [
      <Link key="create" to="/promoCodes/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Promo Code
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: "Create",
        icon: PlusOutlined,
        path: "/promoCodes/create",
        component: PromoCodeForm,
      },
      {
        title: "Update",
        hideFromMenu: true,
        path: "/promoCodes/update/:id",
        component: PromoCodeForm,
      },
    ],
  },
  {
    title: "Data Import",
    icon: UploadOutlined,
    hideFromMenu: true,
    path: "/dataImports",
    dataRoute: Api.dataImports.statistics,
    component: React.lazy(() => import("./components/courses/CourseForm")),
    buttons: [
      <Link key="create" to="/dataImports/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Import
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: "Create",
        icon: PlusOutlined,
        path: "/dataImports/create",
        component: React.lazy(() => import("./components/courses/CourseForm")),
      },
      {
        title: "Update",
        hideFromMenu: true,
        path: "/dataImports/update/:id",
        component: React.lazy(() => import("./components/courses/CourseForm")),
      },
    ],
  },

  // {
  //   title: "Events",
  //   icon: CalendarOutlined,
  //   hideFromMenu: false,
  //   path: "/events",
  //   dataRoute: Api.events.statistics,
  //   component: EventTable,
  //   buttons: [
  //     <Link key="create" to="/events/create">
  //       <Button type="primary" icon={<PlusOutlined />}>
  //         Create Event
  //       </Button>
  //     </Link>,
  //   ],
  //   routes: [
  //     {
  //       title: "Create",
  //       icon: PlusOutlined,
  //       path: "/events/create",
  //       component: EventForm,
  //     },
  //     {
  //       title: "Update",
  //       hideFromMenu: true,
  //       path: "/events/update/:id",
  //       component: EventSinglePage,
  //     },
  //   ],
  // },

  // {
  //   title: "Blog",
  //   icon: InteractionOutlined,
  //   hideFromMenu: false,
  //   path: "/blogs",
  //   dataRoute: Api.blogs.statistics,
  //   component: BlogTable,
  //   buttons: [
  //     <Link key="create" to="/blogs/create">
  //       <Button type="primary" icon={<PlusOutlined />}>
  //         Create Blog
  //       </Button>
  //     </Link>,
  //   ],
  //   routes: [
  //     {
  //       title: "Create",
  //       icon: PlusOutlined,
  //       path: "/blogs/create",
  //       component: BlogForm,
  //     },
  //     {
  //       title: "Update",
  //       hideFromMenu: true,
  //       path: "/blogs/update/:id",
  //       component: BlogForm,
  //     },
  //   ],
  // },
  {
    title: "Categories",
    icon: OrderedListOutlined,
    dataRoute: Api.categories.statistics,
    path: "/categories",
    buttons: [
      <Link key="create" to="/categories/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Category
        </Button>
      </Link>,
    ],
    component: CategoriesTable,
    routes: [
      {
        title: "Create",
        icon: PlusOutlined,
        path: "/categories/create",
        component: CategoriesForm,
      },
      {
        title: "Update",
        hideFromMenu: true,
        path: "/categories/update/:id",
        component: CategoriesForm,
      },
    ],
  },
  {
    title: "Tags",
    icon: TagsOutlined,
    path: "/tags",
    dataRoute: Api.tags.statistics,
    component: TagTable,
    buttons: [
      <Link key="create" to="/tags/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Add Tag
        </Button>
      </Link>,
    ],
    routes: [
      {
        title: "Create",
        icon: PlusOutlined,
        path: "/tags/create",
        component: TagForm,
      },
      {
        title: "Update",
        hideFromMenu: true,
        path: "/tags/update/:id",
        component: TagForm,
      },
    ],
  },
  // {
  //   title: "Portfolio",
  //   icon: BuildOutlined,
  //   path: "/portfolios",
  //   dataRoute: Api.portfolios.statistics,
  //   component: PortfolioTable,
  //   buttons: [
  //     <Link key="create" to="/portfolios/create">
  //       <Button type="primary" icon={<PlusOutlined />}>
  //         Add Portfolio Entry
  //       </Button>
  //     </Link>,
  //   ],
  //   routes: [
  //     {
  //       title: "Create",
  //       icon: PlusOutlined,
  //       path: "/portfolios/create",
  //       component: PortfolioForm,
  //     },
  //     {
  //       title: "Update",
  //       hideFromMenu: true,
  //       path: "/portfolios/update/:id",
  //       component: PortfolioForm,
  //     },
  //   ],
  // },

  {
    title: "Contact Forms",
    icon: FormOutlined,
    path: "/contactForms",
    dataRoute: Api.contactForms.statistics,
    component: ContactFormTable,
    routes: [
      {
        title: "Update",
        hideFromMenu: true,
        path: "/contactForms/update/:id",
        component: ContactFormForm,
      },
    ],
  },
  {
    title: "Orders",
    icon: BoxPlotOutlined,
    path: "/orders",
    dataRoute: Api.orders.statistics,
    component: OrderTable,
    routes: [
      {
        title: "Update Order",
        hideFromMenu: true,
        path: "/orders/update/:id",
        component: OrderSinglePage,
      },
    ],
  },

  {
    title: "Pages",
    icon: TagsOutlined,
    buttons: [],
    path: "/pages",
    dataRoute: Api.pages.statistics,
    component: PageTable,
    routes: [
      {
        title: "Page Management",
        hidePageHeader: true,
        hideFromMenu: true,
        path: "/pages/update/:id",
        component: PageForm,
      },
    ],
  },
  {
    title: "Settings",
    icon: SettingOutlined,
    path: "/settings",
    component: SettingsPage,
  },
];
